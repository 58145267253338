<template>
  <el-card>
    <div slot="header">
      <h3>我的预约订单</h3>
      <el-alert
          title="预约订单只可在预约时间前24小时取消预约，即将到达预约时间的24小时内无法取消！"
          :closable="false"
          type="info">
      </el-alert>
    </div>

    <div class="body">
      <el-tabs v-model="tabActive">
        <el-tab-pane label="预约订单" name="first"></el-tab-pane>
        <el-tab-pane label="失效订单" name="second"></el-tab-pane>
      </el-tabs>
      <el-form :inline="true" :model="query" class="text-center">
        <el-form-item>
          <el-date-picker
              v-model="query.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.id" placeholder="请输入ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>

      <el-row class="content">
        <el-col class="item" :span="12" v-for="i in 5" :key="i">
          <el-row>
            <el-col :span="8">
              <el-image :src="url" fit="fill"></el-image>
            </el-col>
            <el-col :span="16">
              <h3>荣竹杰</h3>
              <p>预约时间：2021-09-28 12:30</p>
              <p>订单编号：000001</p>

              <el-button type="primary">开始咨询</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination>
    </div>


  </el-card>
</template>

<script>
  export default {
    name: "OrderIndex",
    data() {
      return {
        tabActive: 'first',
        query: {},
        url: require("../../../assets/avatar/上官芝.jpg")
      }
    }
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    /deep/ .el-card__header {
      padding: 18px 20px 0px 20px;

      h3 {
        padding: 0px;
        margin: 0px;
      }

      .el-alert {
        margin-top: 10px;
      }
    }

    .body {
      .content {
        padding: 20px;
        .item {
          margin-bottom: 40px;

          h3 {
            margin-top: 0px;
          }

          .el-image {
            width: 125px;
            height: 130px;
          }

          p {
            margin: 5px 0px;
            color: #666666;
          }

          .el-button {
            margin-top: 12px;
          }
        }
      }
      /deep/ .el-pagination{
        text-align: center;
      }

    }

  }


</style>
